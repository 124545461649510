<template>
    <div class="quality">
        <div class="qua-1">
            <img src="https://khome2.tuzuu.com/vita/ahome_1.png">
            <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">
        </div>

        <div class="tab flex-center">
            <div :class="{'tab-item':true,'tab-active':active === 0}" @click="changeActive(0)">
                <span>质量保证</span>
            </div>
            <div :class="{'tab-item':true,'tab-active':active === 1}" @click="changeActive(1)">
                <span>严谨科学</span>
            </div>
            <div :class="{'tab-item':true,'tab-active':active === 2}" @click="changeActive(2)">
                <span>专业团队</span>
            </div>
            <div class="tab-sub" ref="sub"></div>
        </div>

        <div style="overflow: hidden;width: 100%">
            <div class="tab-con" :style="{transform: `translate3d(-${active*100}%, 0px, 0px)`}">
                <div :class="{'tab-con-item':true,'inactive':active !== 0}">
                    <div class="qua-2">
                        <div class="tab-title">UitaUita｜唯你100%的质量保证</div>
                        <div class="title">
                            <p>「UitaUita」每日营养专家</p>
                            <p>来自澳洲的健康管理品牌，通过国际公认的GMP、FDA、SGS认证。</p>
                        </div>
                        <div class="hr"></div>

                        <div class="qua-item" style="margin-bottom: 30px">
                            <div class="title">原料检验的层层把关，通过SGS认证</div>
                            <div class="flex-between" style="align-items: end">
                                <div class="left flex-column-center">
                                    <van-image width="90px" height="90px" src="https://khome2.tuzuu.com/vita/ren_3.png?v=1"/>
                                    <span>SGS认证</span>
                                </div>
                                <div class="right">
                                    创建于1878年，是世界上领先的第三方从事产品质量控制和技术鉴定的跨国公司。
                                    总部设在日内瓦,在世界各地设有1800多家分支机构和专业实验室和 59000多名专业技术人员，在142个国家开展产品质检、监控和保证活动。
                                </div>
                            </div>
                        </div>
                        <div class="qua-item" style="margin-bottom: 30px">
                            <div class="title">生产过程的层层把关，通过国际公认的GMP、FDA认证</div>
                            <div class="flex-between" style="align-items: end">
                                <div class="left flex-column-center">
                                    <van-image width="90px" height="90px" src="https://khome2.tuzuu.com/vita/ren_1.png?v=1"/>
                                    <span>GMP认证</span>
                                </div>
                                <div class="right">
                                    GMP，中文含义是“良好生产规范”。世界卫生组织于1975年公布GMP标准，将GMP定义为指导食物、药品、医疗产品生产和质量管理的法规。
                                </div>
                            </div>
                        </div>
                        <div class="qua-item" style="margin-bottom: 30px">
                            <div class="title">良好的生产规范认证</div>
                            <div class="flex-between" style="align-items: end">
                                <div class="left flex-column-center">
                                    <van-image width="90px" height="90px" src="https://khome2.tuzuu.com/vita/ren_2.png?v=1"/>
                                    <span>FDA认证</span>
                                </div>
                                <div class="right">
                                    FDA是一个由医生、律师、微生物学家、药理学家、化学家和统计学家等专业人士组成的致力于保护、促进和提高国民健康的政府卫生管制的监控机构。
                                    通过FDA认证的食品、药品、化妆品和医疗器具对人体是确保安全而有效的。
                                </div>
                            </div>
                        </div>
                        <div class="end-title">
                            包装过程的层层把关，全程采用最高要求——药品级包装，安全可靠
                        </div>
                    </div>
                </div>
                <div :class="{'tab-con-item':true,'inactive':active !== 1}">
                    <div class="qua-2">
                        <div class="tab-title">我们为什么不直接通过问卷提供方案</div>
                        <div class="title">
                            <p>我们理解现在通用的做法是透过膳食频率问卷法（FFQs）来获取您每日的营养摄取量，从而给您定制每日营养方案</p>
                        </div>
                        <div class="hr"></div>
                        <div class="qua-item">
                            <div class="item-title">膳食频率问卷法的优点：</div>
                            <ul>
                                <li class="flex">它们可以较好的捕获偶尔或偶尔食用的食物的消费量；</li>
                                <li class="flex"> 它们容易大规模的应用于识字人群，同时也更容易 处理。</li>
                            </ul>
                        </div>
                        <div class="qua-item" style="margin-top: 25px">
                            <div class="item-title">但是，膳食频率问卷法也有很多缺点：</div>
                            <ul>
                                <li class="flex">
                                    FFQs缺乏关于如何准备食物的详细信息，也没有关于具体消耗多少和何时消耗的信息，如原本食物中的微量元素，大部分基本在烹饪的情况下会
                                    衰减甚至会消失，而FFQs无法应对此类情况；
                                </li>
                                <li class="flex">
                                    由于FFQs仅限于固定的食物清单，因此它们无法捕捉不同的人的习惯；
                                </li>
                                <li class="flex">
                                    FFQs更容易受到社会期望偏差的影响，人们会高估水果和蔬菜等“健康”食品的消费程度。
                                </li>
                            </ul>
                        </div>

                        <div class="end-title">哈佛的FFQs发明者Walter Willett认为，FFQs的准确率仅为60-70%</div>
                        <div class="end-title" style="font-weight: 400;margin-bottom: 8px">因此，我们通过——</div>
                        <HomeDesc/>
                        <div class="end-info" style="margin-top: 30px">
                            基因检测是精准医学分析的一种方法，每个人的DNA基因都是独特的个人化资讯，这造成了每个人的先天体质，健康状况和特征都不相同，2008年，美国时代杂志曾把这个革命性的技术评选为2008年度最佳创新之首。
                        </div>
                        <div class="end-info" style="margin-bottom: 0px">
                            因此即便是相同饮食习惯的人，因为基因的差异，可能导致个体的基础代谢速率不同，营养的吸收也不一样。
                        </div>
                    </div>


                </div>

                <div :class="{'tab-con-item':true,'inactive':active !== 2}">
                    <div class="qua-2">
                        <div class="column-title flex" style="margin-bottom: 17px">
                            关于UitaUita
                        </div>
                        <div class="desc">
                            「UitaUita」始创于澳洲，前身是一群由国际持证营养师、执业医师、基因科学家等组织在一起的健康管理工作室，通过互联网给北美、欧洲的个人客户和企业客户提供健康咨询与健康改善方案。
                        </div>
                        <div class="desc">
                            2019年初，我们开始和中国一流的基因科技公司和检测科技公司开展合作，为中国用户通过基因检测、代谢检测等精准手段，定制“一人一案”的精准营养方案。
                        </div>
                        <div class="desc" style="margin-bottom: 14px">
                            在长达两年的实践中，我们通过大数据分析，发现中国人在饮食习惯、生活习惯和工作习惯上与欧美人有极大的差异，同时在相关基因数据上也存在显著差异性，因此中国人的营养品配方，不能完全按照欧美人群来设计，必须进行精准化的改良定制。
                        </div>
                        <div class="column-title flex" style="margin-bottom: 20px">
                            部分顾问团队
                        </div>
                        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#666666">
                            <van-swipe-item class="swipe-item" v-for="(item,index) in list" :key="index">
                                <div class="flex-column-center">
                                    <van-image width="160px" round :src="item.image"/>
                                    <div class="position">{{ item.desc }}</div>
                                    <div class="name">{{ item.name }}</div>
                                </div>
                                <ul class="list">
                                    <li v-for="(info,index) in item.info" :key="index" class="flex"
                                        style="align-items: end">
                                        {{ info }}
                                    </li>
                                </ul>
                            </van-swipe-item>
                        </van-swipe>
                        <div class="desc">
                            <p>2020年，我们确定了中国境内具备高度行业专业性和互联网运营经验的运营合作伙伴——中康未来健康科技有限公司，同时，它也是博鳌乐城国际医疗先行区理事长单位和博鳌乐城医疗健康产业促进会理事单位。</p>
                            <p>其中，海南博鳌乐城国际医疗先行区，被誉为“博鳌亚洲论坛第二乐章”，2018年4月11日，习近平总书记考察博鳌乐城国际医疗先行区，对其与国际接轨的先进医疗水平表示肯定。</p>
                            <p>博鳌乐城医疗先行区通过国家赋予的“国九条”政策，以特许医疗、特许研究、特许经营、特许国际医疗交流的“4个特许”政策优惠，来实现医疗技术、设备、药品与国际先进水平“三同步”，是目前全国唯一的“医疗特区”。</p>
                            <p>博鳌乐城医疗健康产业促进会共享“医疗特区”的国际化医疗资源优势，利用先行区内顶级的医疗团队、设施设备，为客户提供综合医疗健康服务。</p>
                        </div>

                        <div class="column-title flex">
                            检测合作方：
                        </div>
                        <img src="https://khome2.tuzuu.com/vita/logo2.png" style="width: 50%;margin: 25px 0px">
                        <div class="desc">
                            我们相信精准科学的力量，并以此作为基础，给予用户每日营养定制计划。
                        </div>
                        <div class="desc">
                            <p>我们的基因检测的合作公司，迪安诊断，于2011年在中国A股上市。</p>
                            <p>迪安诊断专注于诊断技术平台创新，获批中国首批肿瘤诊断与治疗项目高通量基因测序技术试点单位、中国首批基因检测技术临床应用示范中心、中国第三方高性能医学诊疗设备应用示范中心。</p>
                        </div>
                        <div class="desc">
                            霍普金斯大学、美国丹纳赫集团、美国FMI、美国Agena、美国司法鉴定机构NMS、美国马里兰州司法局等多家国际知名机构达成战略合作。目前已在总过布局40家连锁化实验室，为中国超过20000家医疗机构、3亿人提供服务。
                        </div>

                        <div class="footer flex-between">
                            <img src="https://khome2.tuzuu.com/vita/logo_3.png">
                            <div>
                                <p>「UitaUita」——每日营养专家</p>
                                <p>Simple One，Simple Health</p>
                                <p>每天一包，让健康简简单单</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import HomeDesc from "../../components/HomeDesc";

    export default {
        name: "",
        data() {
            return {
                active: 0,
                list: [
                    {
                        image: 'https://khome2.tuzuu.com/vita/preson_4.png',
                        name: '营养学博士',
                        desc: '布伦达·佩拉塔·罗梅罗',
                        info: [
                            '认证注册营养师、营养基因学和营养遗传学专家',
                            '5年临床营养评估、膳食计划和营养治疗经验',
                            '3年糖尿病患者营养治疗经验'
                        ]
                    },
                    {
                        image: 'https://khome2.tuzuu.com/vita/preson_5.png',
                        name: '营养学博士',
                        desc: '凯瑟琳·斯·格瓦西奥',
                        info: [
                            '认证注册营养师、精准营养认证教练、运动营养认证教练',
                            '16年饮食与健康营养顾问专家经验'
                        ]
                    },
                    {
                        image: 'https://khome2.tuzuu.com/vita/preson_6.png',
                        name: '麦吉尔大学心理学学士',
                        desc: '莎拉·索米安',
                        info: [
                            '加拿大整体营养研究所营养专家',
                            '预防性健康和慢性疾病营养治疗培训导师'
                        ]
                    }
                ]
            }
        },
        components: {
            HomeDesc
        },
        mounted() {
            this.changeActive()
        },
        methods: {
            changeActive(index = 0) {
                this.active = index
                let selectObj = document.querySelectorAll(".tab-item span")[this.active]
                let offLeft = selectObj?.offsetLeft + 13
                document.title = selectObj.innerText
                this.$refs.sub.style.left = offLeft + 'px'
            },
        }
    }
</script>

<style scoped lang="less">
    .quality {
        width: 100%;
        min-height: 100vh;
        background-color: #F2F2F2;
        font-size: 16px;
    }

    .tab {
        position: relative;
        padding: 0px 20px;
        width: 100%;
        box-sizing: border-box;
        height: 55px;
        margin-top: -130px;

        .tab-item {
            color: white;
            flex: 1;
            text-align: center;
            font-size: 17px;
            transition: all 0.2s;
            font-weight: 500;
            letter-spacing: 1px;
            font-family: HYQiHei-FZS, HYQiHei;
        }

        .tab-sub {
            position: absolute;
            width: 12%;
            background: white;
            height: 4px;
            border-radius: 50px;
            bottom: 8px;
            left: 40px;
            transition: all 0.3s;
            border-radius: 10px;
        }

        .tab-active {
            font-weight: 900;
            font-size: 20px;
        }
    }

    .tab-con {
        width: 100%;
        height: 100%;
        display: flex;
        transition: all 0.3s;
        box-sizing: border-box;

        .inactive {
            animation: move 0.1s linear 0.2s;
            animation-fill-mode: both;
        }
    }

    .tab-con-item {
        width: 100%;
        flex-shrink: 0;
        padding: 25px 10px;
        box-sizing: border-box;

        .qua-2 {
            width: 100%;
            border-radius: 10px;
            padding: 25px 15px;
            box-sizing: border-box;

            background-color: white;
        }

        .footer {
            margin-top: 40px;
            font-size: 14px;
            color: #3F3F3F;
            line-height: 22px;
            align-items: end;
            font-weight: 700;
            text-align: right;

            img {
                width: 33%;
                margin-top: 5px;
            }

        }

        .desc {
            font-size: 15px;
            color: #7e7e7e;
            line-height: 27px;
            margin-top: 10px;
        }

        .my-swipe {
            padding-bottom: 30px;
            margin-bottom: 20px;

            /deep/ .van-swipe__indicator--active {
                width: 30px;
                border-radius: 10px;
                transition: all 0.4s;
            }

            /deep/ .van-swipe__indicator {
                background-color: #D8D8D8;
            }

            .position {
                margin-top: 25px;
                line-height: 22px;
                font-size: 16px;
                font-weight: 700;
                color: #292C2F;
            }

            .name {
                color: #3F3F3F;
                margin-top: 10px;
                font-size: 13px;
            }

            .list {
                margin-top: 14px;
                font-size: 14px;
                color: #232323;
                line-height: 30px;

                li {
                    display: flex;

                    &:before {
                        content: '-';
                        display: block;

                    }
                }
            }
        }

        .column-title {
            font-size: 18px;
            color: #292C2F;
            font-weight: 700;
            margin-top: 20px;

            &:first-of-type {
                margin-top: 0px;
            }

            &:before {
                content: '';
                display: block;
                width: 5px;
                height: 20px;
                background-color: #6B00AF;
                border-radius: 8px;
                margin-right: 5px;
            }
        }

        .tab-title {
            font-size: 18px;
            color: #3F3F3F;
            font-weight: 700;
            text-align: center;
            margin-bottom: 14px;
        }

        .title {
            font-size: 14px;
            color: #3F3F3F;
            line-height: 23px;
        }

        .hr {
            height: 1px;
            width: 100%;
            border-bottom: 1px solid #292C2F;
            margin-top: 15px;
            margin-bottom: 20px;
        }

        .qua-item {
            margin-bottom: 17px;

            .item-title {
                font-size: 14px;
                color: #3F3F3F;
                margin-bottom: 20px;
                font-weight: 700;
            }

            .title {
                color: #3F3F3F;
                font-size: 14px;
                margin-bottom: 10px;
                font-weight: 700;
            }

            .left {
                font-size: 14px;
                color: #3F3F3F;
                font-weight: 500;

                .van-image {
                    //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
            }

            .right {
                font-size: 14px;
                line-height: 23px;
                color: #7e7e7e;
                margin-left: 10px;
            }

            ul {
                color: #7e7e7e;
                font-size: 14px;
                counter-reset: section;

                li {
                    align-items: end;
                    line-height: 25px;


                    &:before {
                        counter-increment: section;
                        content: counter(section) ".";
                    }

                }
            }
        }

        .end-title {
            font-size: 16px;
            color: #3f3f3f;
            line-height: 23px;
            font-weight: 700;
            margin-top: 20px;
        }

        .end-info {
            font-size: 14px;
            color: #292C2F;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    @keyframes move {
        100% {
            height: 0;
            overflow: visible;
        }

    }
</style>